<template>
  <section class="categories-menu">
    <div class="add-new-category-btn">
      <v-btn color="primary" dark @click="addNew">{{ $t('Add new category') }}</v-btn>
    </div>
    <v-navigation-drawer v-model="showMenu">
      <v-treeview
        :items="getTree"
        activatable
        dense
        transition
        item-key="entity_id"
        @update:active="selectedCategory"
      >
        <template v-slot:label="{ item }">
          <div class="title-tree"><v-icon class="mr-3" color="red" v-if="item.product_count === 0">mdi-alert</v-icon> {{ $t(item.name) }} </div>
        </template>
      </v-treeview>
    </v-navigation-drawer>
    <div class="add-new-category-btn">
      <v-btn color="primary" dark @click="addNew">{{ $t('Add new category') }}</v-btn>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CategoriesTree',
  props: {
    activeCategory: {
      type: Number
    }
  },
  data () {
    return {
      showMenu: true
    }
  },
  computed: {
    ...mapGetters({
      getTree: 'catalog/category/getTree',
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      loadTree: 'catalog/category/loadTree'
    }),
    addNew () {
      this.$emit('add-new-category', true)
    },
    selectedCategory (categoryIds) {
      this.$emit('selected', categoryIds)
    }
  },
  mounted() {
    this.loadTree(this.token)
  }
}
</script>
<style lang="scss" scoped>
.add-new-category-btn {
  padding: 5px 0;
}
.active {
  .v-list-item__title {
    color: #f5a623;
  }
  &:before {
    color: #f5a623;
    opacity: 0.12;
  }
}
.title-tree {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1rem;
}
.categories-menu {
  max-height: calc(100vh - 84px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .v-navigation-drawer {
    flex: 1 1 auto;
  }
}
</style>
