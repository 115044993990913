import { render, staticRenderFns } from "./Categories.vue?vue&type=template&id=ff340a1e&scoped=true&"
import script from "./Categories.vue?vue&type=script&lang=js&"
export * from "./Categories.vue?vue&type=script&lang=js&"
import style0 from "./Categories.vue?vue&type=style&index=0&id=ff340a1e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff340a1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VDialog,VFlex,VIcon,VLayout,VList,VTreeview})
