<template>
  <tr class="v-data-table__mobile-table-row product-line">
    <td class="v-data-table__mobile-row product-name left">{{ item.name }}<br/><span class="item-price">{{ Math.abs(item.price).toFixed(2)| toUAH }}</span></td>
    <template>
      <td class="v-data-table__mobile-row status-product left">
        <v-switch v-model="status" :loading="statusLoading" @change="updateStatus" class="status-switch" color="orange" inset flat hide-details />
      </td>
    </template>
    <template v-if="showSort">
      <td class="v-data-table__mobile-row row-draggable left">
        <v-icon>mdi-drag-vertical</v-icon>
      </td>
    </template>
  </tr>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CategoryProductListTableItemMobile',
  props: {
    item: {
      type: Object,
      required: true
    },
    categoryId: {
      type: Number,
      default: 0
    },
    showSort: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editPrice: false,
      newPrice: null,
      priceLoading: false,
      status: 0,
      position: 0,
      statusLoading: false,
      editSort: false,
      sortLoading: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      updateProduct: 'catalog/product/update',
      updateCategory: 'catalog/category/save'
    }),
    updateStatus () {
      this.statusLoading = true
      this.updateProduct({ token: this.token, product_id: this.item.entity_id, product: { status: this.status } })
        .then(result => {
          this.$bus.$emit('catalog-product-update', { product_id: this.item.entity_id, product: result.product })
        })
        .finally(() => {
          this.statusLoading = false
        })
    },
    openProductEditModal () {
      this.$bus.$emit('catalog-product-edit', this.item.entity_id)
    }
  },
  mounted () {
    this.status = this.item.status === 2 ? 0 : this.item.status
    this.position = this.item.position ? this.item.position : 0
  }
}
</script>
<style lang="scss" scoped>
.item-clickable {
  justify-content: flex-end;
  margin: 0;
  cursor: pointer;
}

.product-line {
  height: 65px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  margin: 2px 8px 2px 0px;
}

.product-line:hover {
  background: #eeeeee;
  cursor: pointer;
}

.status-switch {
  margin: 0;
  padding: 0;
  border-bottom: none;
}
.desktop-button {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}
.mobile-button {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}
.product-name {
  @media (max-width: 600px) {
    width: 80%;
    border: none;
  }
}
.status-product {
  @media (max-width: 600px) {
    width: 10%;
    border-bottom: none;
    border-left: 1px solid #c6c6c6;
  }
}
.item-price {
  @media (max-width: 600px) {
    color: #999999;
  }
}
.row-draggable {
  @media (max-width: 600px) {
    width: 10%;
    border-left: 1px solid #c6c6c6;
  }
}

.left {
  text-align: left;
}
</style>
<style lang="scss">
  .status-switch {
    .v-input__slot {
      justify-content: left;
    }
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 10px 8px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td.status-product {
    padding: 10px 0px 10px 18px;
  }
  .v-data-table__mobile-row {
    align-items: center;
    display: table-cell;
  }
  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
    border-bottom: none;
  }
</style>
